import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import Spinner from '../../spinner'
import Breadcrumb from '../../breadcrumb'
import { Formulario as FormularioDoFormik, Select, Telefone, Input, Checkbox } from '../../formik/formulario'
import { formatarCpf } from '../../../bibliotecas/formatacao'
import { formatarDataParaFormatoLocal } from '../../../bibliotecas/data'
import Permissao, { usePossuiAsPermissoes } from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import * as Yup from 'yup'
import moment from 'moment'
import { WhatsApp } from '../../../design/icones'
import PreCadastroDePaciente from './modais/pre-cadastro-do-paciente'

import {
  RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO,
  LISTAR_GRAUS_DE_RELACOES,
  LISTAR_PROPRIETARIOS_DE_TELEFONES
} from '../../../acoes/tipos'

export default function Passo1(props) {
  const {
    adicionarPreCadastroDoPaciente,
    convenios,
    grausDeRelacoes,
    history,
    limparDadosDoAgendamento,
    limparDadosDoPaciente,
    listarGrausDeRelacoes,
    listarPacientesDoAgendamento,
    listarProprietariosDeTelefones,
    match,
    ordem,
    paciente,
    pacientes,
    proprietariosDeTelefones,
    recuperarConvenio,
    selecionarConvenioDoAgendamento,
    selecionarPacienteDoAgendamento,
    selecionarContatos,
    sexos,
    contatos: {
      telefone1,
      proprietarioDoTelefone1,
      nomeDoTelefone1,
      grauDeRelacaoDoTelefone1,
      telefone1EhWhatsapp,
      telefone2,
      proprietarioDoTelefone2,
      nomeDoTelefone2,
      grauDeRelacaoDoTelefone2,
      telefone2EhWhatsapp,
      email
    }
  } = props

  const [pacientePreCadastrado, setPacientePreCadastrado] = useState('')
  const [valor, setValor] = useState('')
  const [exibirPreCadastroDoPaciente, setExibirPreCadastroDoPaciente] = useState(false)
  const habilitarFuncoes = usePossuiAsPermissoes([permissoes.ADICIONAR_AGENDAMENTO_PARA_O_PROFISSIONAL_DE_SAUDE])

  useEffect(() => {
    if (paciente.identificador) {
      recuperarConvenio(paciente.identificador)
      listarPacientesDoAgendamento({ termo: paciente.nome }, 1, ordem, 50)
    }
    return () => limparDadosDoAgendamento()
  }, [paciente, recuperarConvenio, limparDadosDoAgendamento, listarPacientesDoAgendamento, ordem])

  useEffect(() => { listarGrausDeRelacoes() }, [listarGrausDeRelacoes])
  useEffect(() => { listarProprietariosDeTelefones() }, [listarProprietariosDeTelefones])

  const limparPasso1 = () => {
    limparDadosDoPaciente()
    limparDadosDoAgendamento()
    setPacientePreCadastrado('')
    setValor('')
    listarPacientesDoAgendamento({ termo: '', ativo: ['Ativo'] }, 1, ordem, 50)
  }

  const selecionarPaciente = identificador => {
    if (identificador) {
      selecionarPacienteDoAgendamento(identificador)
    } else {
      limparPasso1()
    }
  }

  function abrir() { setExibirPreCadastroDoPaciente(true) }
  function fechar() { setExibirPreCadastroDoPaciente(false) }

  const enviarDadosDoPaciente = formulario => {
    selecionarConvenioDoAgendamento(formulario.convenio)
    selecionarContatos({
      telefone1: formulario.telefone1,
      proprietarioDoTelefone1: formulario.proprietarioDoTelefone1,
      nomeDoTelefone1: formulario.nomeDoTelefone1,
      grauDeRelacaoDoTelefone1: formulario.grauDeRelacaoDoTelefone1,
      telefone1EhWhatsapp: formulario.telefone1EhWhatsapp,
      telefone2: formulario.telefone2,
      proprietarioDoTelefone2: formulario.proprietarioDoTelefone2,
      nomeDoTelefone2: formulario.nomeDoTelefone2,
      grauDeRelacaoDoTelefone2: formulario.grauDeRelacaoDoTelefone2,
      telefone2EhWhatsapp: formulario.telefone2EhWhatsapp,
      email: formulario.email
    })
    history.push(`/agendamentos/novo-agendamento/passo-2`)
  }

  const renderizarItemPaciente = ({ value, label, dataDeNascimento, cpf }) => {
    return (
      <div key={value}>
        <div className='container-item-auto-complete-paciente'>
          <div className='nome'>
            <span>{label}</span>
          </div>
          {!paciente.identificador &&
            <div className='data-de-nascimento'>
              <span>Nasc.: {dataDeNascimento}</span>
            </div>
          }
        </div>
        <div className={`segunda-linha-auto-complete-pacientes`}>
          {cpf &&
            <div className='medico-do-paciente'>
              <span>CPF: {formatarCpf(cpf, 11)}</span>
            </div>
          }
        </div>
      </div>
    )
  }

  const pesquisarPacientes = termo => {
    setPacientePreCadastrado(termo)
    if (termo.length > 2) {
      listarPacientesDoAgendamento({ termo: termo, ativo: ['Ativo'] }, 1, ordem, 50)
    }
  }

  const optionsPaciente = pacientes.dados && pacientes.dados.map(x => ({
    value: x.identificador,
    label: x.nome,
    dataDeNascimento: x.dataDeNascimento && formatarDataParaFormatoLocal(new Date(x.dataDeNascimento)),
    cpf: x.cpf && formatarCpf(x.cpf, 11)
  }))

  return (
    <div className='page-paciente-dados'>
      <Modal
        isOpen={exibirPreCadastroDoPaciente}
        className='modal'
      >
        <PreCadastroDePaciente
          adicionarPreCadastroDoPaciente={adicionarPreCadastroDoPaciente}
          listarPacientesDoAgendamento={listarPacientesDoAgendamento}
          match={match}
          nomeDoPaciente={pacientePreCadastrado || valor}
          fechar={fechar}
          ordem={ordem}
          selecionarPacienteDoAgendamento={selecionarPacienteDoAgendamento}
          sexos={sexos}
        />
      </Modal>
      <div className='container'>
        <div className='main-content-form'>
          <div className='header-page-form'>
            <Breadcrumb />
          </div>
          <Spinner operacoes={[LISTAR_PACIENTES_DO_WIZARD_DE_AGENDAMENTO]}>
            {({ processando, setFieldValue, values }) => (
              <Spinner operacoes={[
                RECUPERAR_CONVENIOS_DO_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
                SELECIONAR_PACIENTE_DO_WIZARD_DE_AGENDAMENTO,
                LISTAR_GRAUS_DE_RELACOES,
                LISTAR_PROPRIETARIOS_DE_TELEFONES
              ]}>
                <FormularioDoFormik
                  reinicializar={true}
                  valoresIniciais={{
                    paciente: (paciente ? paciente.identificador : ''),
                    telefone1: telefone1 ? telefone1 : '',
                    proprietarioDoTelefone1: proprietarioDoTelefone1 ? proprietarioDoTelefone1 : '',
                    nomeDoTelefone1: nomeDoTelefone1 ? nomeDoTelefone1 : '',
                    grauDeRelacaoDoTelefone1: grauDeRelacaoDoTelefone1 ? grauDeRelacaoDoTelefone1 : '',
                    telefone1EhWhatsapp: telefone1EhWhatsapp ? telefone1EhWhatsapp : false,
                    telefone2: telefone2 ? telefone2 : '',
                    proprietarioDoTelefone2: proprietarioDoTelefone2 ? proprietarioDoTelefone2 : '',
                    nomeDoTelefone2: nomeDoTelefone2 ? nomeDoTelefone2 : '',
                    grauDeRelacaoDoTelefone2: grauDeRelacaoDoTelefone2 ? grauDeRelacaoDoTelefone2 : '',
                    telefone2EhWhatsapp: telefone2EhWhatsapp ? telefone2EhWhatsapp : false,
                    email: email ? email : '',
                    convenio: (paciente.identificador && convenios && convenios.length > 2 && convenios[0].identificador) || '',
                  }}
                  esquemaDeValidacoes={Yup.object().shape({
                    paciente: Yup.string().required('Obrigatório.'),
                    telefone1: Yup.string()
                      .when("telefone2", {
                        is: val => val === '' || val === undefined,
                        then: Yup.string().required('Obrigatório.')
                      })
                      .nullable()
                      .ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999'),
                    proprietarioDoTelefone1: Yup.string()
                      .when("telefone2", {
                        is: val => val === '' || val === undefined,
                        then: Yup.string().required('Obrigatório.')
                      })
                      .nullable(),
                    nomeDoTelefone1: Yup.string()
                      .when('proprietarioDoTelefone1', {
                        is: val => val !== 'Paciente',
                        then: Yup.string().required('Obrigatório.').min(3, 'O nome deve possuir no mínimo 3 caracteres.')
                      }),
                    grauDeRelacaoDoTelefone1: Yup.string()
                      .when('proprietarioDoTelefone1', {
                        is: val => val !== 'Paciente' && val !== '' && val !== undefined,
                        then: Yup.string().required('Obrigatório.')
                      }),
                    telefone2: Yup.string()
                      .nullable()
                      .ehUmNumeroDeTelefoneValido('Telefone inválido. Ex.: 99 9999-9999'),
                    proprietarioDoTelefone2: Yup.string()
                      .when("telefone2", {
                        is: val => val !== '' && val !== undefined,
                        then: Yup.string().required('Obrigatório.')
                      })
                      .nullable(),
                    nomeDoTelefone2: Yup.string()
                      .when('proprietarioDoTelefone2', {
                        is: val => val !== 'Paciente' && val !== '' && val !== undefined,
                        then: Yup.string().required('Obrigatório.').min(3, 'O nome deve possuir no mínimo 3 caracteres.')
                      }),
                    grauDeRelacaoDoTelefone2: Yup.string()
                      .when('proprietarioDoTelefone2', {
                        is: val => val !== 'Paciente' && val !== '' && val !== undefined,
                        then: Yup.string().required('Obrigatório.')
                      }),
                    email: Yup.string().ehUmEmailValido('E-mail deve ser válido.'),
                  }, [['telefone1', 'telefone2'], ['telefone2', 'telefone1']])}
                  acao={enviarDadosDoPaciente}
                >
                  {({ values, setFieldValue }) => (
                    <>
                      <div className='nav-route-agendamento justify-content-center'>
                        <div className='nav-convenio'>
                          <ol className='nav-step'>
                            <li className='nav-step-item active'>1</li>
                            <li className='nav-step-item'> / 3</li>
                          </ol>
                          <p>Dados do Paciente</p>
                        </div>
                      </div>
                      <div className='mb-4'>
                        <fieldset>
                          <h2 className='form-title'>Novo Agendamento (Wizard)</h2>
                          <r-grid columns-md={12} columns-lg={12}>
                            <r-cell span={4} span-md={9} span-lg={9}>
                              <Select
                                nome='paciente'
                                tabIndex={1}
                                titulo='Paciente *'
                                itens={pacientes.dados}
                                campoCodigo='identificador'
                                campoDescricao='nome'
                                formatOptionLabel={renderizarItemPaciente}
                                onChange={identificador => selecionarPaciente(identificador)}
                                options={optionsPaciente}
                                isLoading={processando}
                                filterOption={() => true}
                                inputValue={paciente.identificador ? '' : (pacientePreCadastrado || valor)}
                                onInputChange={pesquisarPacientes}
                                onMenuClose={() => setValor(pacientePreCadastrado)}
                                onFocus={() => pesquisarPacientes(pacientePreCadastrado || valor)}
                              />
                            </r-cell>
                            <r-cell span={4} span-md={3} span-lg={3}>
                              <Permissao permissoes={[permissoes.ADICIONAR_PACIENTE]}>
                                <button
                                  className='button --plus --primary w-100 alinha-botao'
                                  type='button'
                                  disabled={paciente.identificador}
                                  onClick={() => abrir()}
                                  tabIndex={2}
                                >
                                  Adicionar
                                </button>
                              </Permissao>
                            </r-cell>
                            {paciente.identificador &&
                              <r-cell span={4} span-md={12} span-lg={12}>
                                <div className='form-item'>
                                  {paciente.dataDeNascimento &&
                                    <div className='form-item__choice'><strong>Data de Nascimento:</strong> {moment(paciente.dataDeNascimento).format('DD/MM/YYYY')}</div>
                                  }
                                  {paciente.medicoDeReferencia &&
                                    <div className='form-item__choice'><strong>Médico de Referência:</strong>{' '}{paciente.medicoDeReferencia.tratamento}{' '}{paciente.medicoDeReferencia.nome}</div>
                                  }
                                  {paciente.unidadePreferencial &&
                                    <div className='form-item__choice'><strong>Unidade Preferencial:</strong> {paciente.unidadePreferencial.nome}</div>
                                  }
                                </div>
                              </r-cell>
                            }
                            <r-cell span={4} span-md={4} span-lg={3}>
                              <Select
                                nome='proprietarioDoTelefone1'
                                tabIndex={3}
                                titulo='Proprietário do telefone'
                                itens={proprietariosDeTelefones}
                                campoCodigo='nome'
                                campoDescricao='nome'
                                onChange={() =>
                                  setFieldValue('nomeDoTelefone1', '') &&
                                  setFieldValue('grauDeRelacaoDoTelefone1', '')
                                }
                                isDisabled={!habilitarFuncoes}
                              />
                            </r-cell>
                            <r-cell span={8} span-md={6} span-lg={3}>
                              <Input
                                type='text'
                                nome='nomeDoTelefone1'
                                tabIndex={4}
                                titulo={values.proprietarioDoTelefone1 === 'Paciente' || !values.telefone1 ? 'Nome' : 'Nome *'}
                                disabled={values.proprietarioDoTelefone1 === 'Paciente' || !habilitarFuncoes}
                              />
                            </r-cell>
                            <r-cell span={4} span-md={4} span-lg={3}>
                              <Select
                                nome='grauDeRelacaoDoTelefone1'
                                tabIndex={5}
                                titulo={values.proprietarioDoTelefone1 === 'Paciente' || !values.telefone1 ? 'Grau de Relação' : 'Grau de Relação *'}
                                itens={grausDeRelacoes}
                                campoCodigo='nome'
                                campoDescricao='nome'
                                isDisabled={values.proprietarioDoTelefone1 === 'Paciente' || !habilitarFuncoes}
                              />
                            </r-cell>
                            <r-cell span={4} span-md={6} span-lg={2}>
                              <Telefone
                                nome='telefone1'
                                tabIndex={6}
                                titulo='Telefone 1'
                              />
                            </r-cell>
                            <r-cell span={1} span-md='1' span-lg='1' title='Telefone é WhatsApp'>
                              <Checkbox
                                nome='telefone1EhWhatsapp'
                                tabIndex={7}
                                titulo={<WhatsApp size={24} className='mt-5n' />}
                                classname='mt-35'
                                title='Telefone é WhatsApp'
                              />
                            </r-cell>
                            <r-cell span={4} span-md={4} span-lg={3}>
                              <Select
                                nome='proprietarioDoTelefone2'
                                tabIndex={8}
                                titulo={values.telefone2 ? 'Proprietário do telefone*' : 'Proprietário do telefone'}
                                itens={proprietariosDeTelefones}
                                campoCodigo='nome'
                                campoDescricao='nome'
                                onChange={() =>
                                  setFieldValue('nomeDoTelefone2', '') &&
                                  setFieldValue('grauDeRelacaoDoTelefone2', '')
                                }
                                isDisabled={!habilitarFuncoes}
                              />
                            </r-cell>
                            <r-cell span={8} span-md={6} span-lg={3}>
                              <Input
                                type='text'
                                nome='nomeDoTelefone2'
                                tabIndex={9}
                                titulo={values.proprietarioDoTelefone2 === 'Paciente' || !values.telefone2 ? 'Nome' : 'Nome *'}
                                disabled={values.proprietarioDoTelefone2 === 'Paciente' || !habilitarFuncoes}
                              />
                            </r-cell>
                            <r-cell span={4} span-md={4} span-lg={3}>
                              <Select
                                nome='grauDeRelacaoDoTelefone2'
                                tabIndex={10}
                                titulo={values.proprietarioDoTelefone2 === 'Paciente' || !values.telefone2 ? 'Grau de Relação' : 'Grau de Relação *'}
                                itens={grausDeRelacoes}
                                campoCodigo='nome'
                                campoDescricao='nome'
                                isDisabled={values.proprietarioDoTelefone2 === 'Paciente' || !habilitarFuncoes}
                              />
                            </r-cell>
                            <r-cell span={4} span-md={6} span-lg={2}>
                              <Telefone
                                nome='telefone2'
                                tabIndex={11}
                                titulo='Telefone 2'
                              />
                            </r-cell>
                            <r-cell span={1} span-md='1' span-lg='1' title='Telefone é WhatsApp'>
                              <Checkbox
                                nome='telefone2EhWhatsapp'
                                tabIndex={12}
                                titulo={<WhatsApp size={24} className='mt-5n' />}
                                classname='mt-35'
                                title='Telefone é WhatsApp'
                              />
                            </r-cell>
                            <r-cell span={8} span-md={6} span-lg={8}>
                              <Input
                                type='text'
                                nome='email'
                                tabIndex={13}
                                titulo='E-mail'
                              />
                            </r-cell>
                            <r-cell span={8} span-md={6} span-lg={4}>
                              <Select
                                nome='convenio'
                                tabIndex={14}
                                titulo='Convênio'
                                itens={convenios}
                                campoCodigo='identificador'
                                campoDescricao='descricao'
                                isDisabled={!paciente.identificador}
                              />
                            </r-cell>
                          </r-grid>
                          <div className='list-btn'>
                            <button tabIndex={15} type='reset' className='button --light' onClick={() => limparPasso1()}>Limpar</button>
                            <button tabIndex={16} type='submit' className='button --primary' disabled={!paciente.identificador}>Continuar</button>
                          </div>
                        </fieldset>
                      </div>
                    </>
                  )}
                </FormularioDoFormik>
              </Spinner>
            )}
          </Spinner>
        </div>
      </div>
    </div>
  )
}