import React, { useEffect } from 'react'
import moment from 'moment'
import { converterObjetoParaArray } from '../../../bibliotecas/conversao'
import { formatarHoraParaFormatoLocal, formatarDataParaFormatoUniversal } from '../../../bibliotecas/data'
import Formulario from './formulario'
import { v4 as uuidv4 } from 'uuid'

export default function Agendamento(props) {
  const {
    adicionarBloqueioNaAgendaDoProfissionalDeSaude,
    adicionarAgendamento,
    adicionarPreCadastroDoPaciente,
    convenios,
    dataBaseHorariosLivres,
    grausDeRelacoes,
    horariosLivres,
    identificadorDoProfissional,
    limparAlertasDoAgendamento,
    limparAgendamentoAtual,
    limparDadosDoPacienteDoAgendamento,
    limparHorariosLivres,
    listarGrausDeRelacoes,
    listarHorariosLivres,
    listarPacientesDoAgendamento,
    listarProprietariosDeTelefones,
    listarRecorrenciasDoAgendamento,
    listarSexos,
    listarTiposDeAgendamentoDoAgendamento,
    listarTiposDeHorarios,
    match,
    motivosDeBloqueio,
    fechar,
    pacientes,
    pacienteSemPrograma,
    profissional,
    proprietariosDeTelefones,
    proximosAgendamentos,
    recorrenciasDoAgendamento,
    recuperarConveniosDoPacienteSelecionadoDoAgendamento,
    salvarAgendamento,
    selecionarPacienteDoAgendamento,
    sexos,
    tiposDeAgendamento,
    tiposDeHorarios,
    unidades,
    valoresIniciais,
    pacienteEmEspera
  } = props

  const unidadesFiltradas = profissional && unidades &&
    converterObjetoParaArray(profissional.unidades).filter(p => converterObjetoParaArray(unidades).some(u => u.identificador === p.identificador))

  useEffect(() => {
    if (!pacienteEmEspera) {
      listarPacientesDoAgendamento({ ativo: ['Ativo'] }, 1, { nome: 'nome', ordem: '+' }, 50)
    }
    return () => limparAgendamentoAtual()
  }, [listarPacientesDoAgendamento, limparAgendamentoAtual, pacienteEmEspera])

  useEffect(() => {
    if (identificadorDoProfissional)
      listarTiposDeAgendamentoDoAgendamento(identificadorDoProfissional)
  }, [listarTiposDeAgendamentoDoAgendamento, identificadorDoProfissional])

  useEffect(() => {
    listarRecorrenciasDoAgendamento()
  }, [listarRecorrenciasDoAgendamento])

  const pesquisarPacientes = async termo => {
    await listarPacientesDoAgendamento({ termo: termo, ativo: ['Ativo'] }, 1, { nome: 'nome', ordem: '+' }, 50)
  }

  const selecionarPaciente = async paciente => {
    const identificadorDoPaciente = paciente && paciente.identificador ? paciente.identificador : null

    if (identificadorDoPaciente) {
      selecionarPacienteDoAgendamento(paciente)
      await recuperarConveniosDoPacienteSelecionadoDoAgendamento(identificadorDoPaciente)
    }
  }

  const adicionarAgendamentoDoProfissional = async formulario => {
    const identificadorDoProfissional = profissional.identificador
    const identificadorDoAgendamento = formulario.identificadorDoAgendamento || null

    const possuiDadosDeRecorrencia = formulario.recorrencia && formulario.recorrencia !== 'nao_recorrente'
    let horaFinal = moment(formulario.horarioDoAgendamento, 'h:mm:ss A')
    horaFinal = horaFinal.add(formulario.duracaoDoAgendamento, 'minutes')
    const horaInicial = formatarHoraParaFormatoLocal(formulario.horarioDoAgendamento).toUpperCase() === 'DATA INVÁLIDA' ? formulario.horarioDoAgendamento : formatarHoraParaFormatoLocal(formulario.horarioDoAgendamento)

    let dados = {
      identificador: identificadorDoAgendamento,
      identificadorDoPaciente: formulario.paciente || '',
      identificadorDoTipo: formulario.tipoDeAgendamento,
      inicio: `${formatarDataParaFormatoUniversal(formulario.data)} ${horaInicial}`,
      fim: `${formatarDataParaFormatoUniversal(formulario.data)} ${formatarHoraParaFormatoLocal(horaFinal)}`,
      telefone1: formulario.telefone1,
      proprietarioDoTelefone1: formulario.proprietarioDoTelefone1,
      nomeDoTelefone1: formulario.nomeDoTelefone1,
      grauDeRelacaoDoTelefone1: formulario.grauDeRelacaoDoTelefone1,
      telefone1EhWhatsapp: formulario.telefone1EhWhatsapp,
      telefone2: formulario.telefone2,
      proprietarioDoTelefone2: formulario.proprietarioDoTelefone2,
      nomeDoTelefone2: formulario.nomeDoTelefone2,
      grauDeRelacaoDoTelefone2: formulario.grauDeRelacaoDoTelefone2,
      telefone2EhWhatsapp: formulario.telefone2EhWhatsapp,
      email: formulario.email,
      identificadorDoConvenio: formulario.convenio !== 'particular' ? formulario.convenio : '',
      observacoes: formulario.observacoes,
      unidadeDaEmpresa: formulario.unidadeDaEmpresa ? formulario.unidadeDaEmpresa : unidadesFiltradas[0].identificador,
      confirmarOAgendamentoQuandoOPacienteNaoPossuiProgramaAtivo: formulario.confirmarOAgendamentoQuandoOPacienteNaoPossuiProgramaAtivo,
      confirmarOAgendamentoQuandoOPacienteJaTemAgendamentosNosProximosMeses: formulario.confirmarOAgendamentoQuandoOPacienteJaTemAgendamentosNosProximosMeses
    }

    if (formulario.convenio && formulario.convenio === 'particular') {
      dados.particular = true
    }

    if (identificadorDoAgendamento) {
      dados.identificador = identificadorDoAgendamento
    }

    if (possuiDadosDeRecorrencia) {
      dados.recorrencia = formulario.recorrencia

      if (formulario.numeroDeRepeticoes) {
        dados.numeroDeRepeticoes = formulario.numeroDeRepeticoes - 1
      }

      if (formulario.ateAData) {
        dados.dataFinal = formulario.ateAData
      }

      if (dados.recorrencia === 'semanal') {
        dados.domingo = formulario.semana.includes('Dom')
        dados.segundaFeira = formulario.semana.includes('Seg')
        dados.tercaFeira = formulario.semana.includes('Ter')
        dados.quartaFeira = formulario.semana.includes('Qua')
        dados.quintaFeira = formulario.semana.includes('Qui')
        dados.sextaFeira = formulario.semana.includes('Sex')
        dados.sabado = formulario.semana.includes('Sáb')
      }
    }

    const acao = identificadorDoAgendamento ? salvarAgendamento : adicionarAgendamento
    const executou = await acao(identificadorDoProfissional, dados)

    return executou
  }

  const adicionarPreCadastroDoPacienteNoAgendamento = async formulario => {
    const dados = {
      ativo: true,
      nome: formulario.nome,
      nomeDaMae: formulario.nomeDaMae,
      sexo: formulario.sexo,
      dataDeNascimento: formulario.dataDeNascimento,
      telefones: [{
        identificador: uuidv4(),
        proprietario: 'paciente',
        nome: '',
        grauDeRelacao: '',
        numero: formulario.telefone
      }],
      observacoes: formulario.observacoes,
      origemDosDados: 'prontuario',
      preCadastro: true,
    }

    const adicionou = await adicionarPreCadastroDoPaciente(dados)

    return adicionou
  }

  const listarHorariosLivresDoProfissional = async (data, tipoDeAgendamento, unidadeDaEmpresaSelecionada) => {
    const identificadorDoProfissional = profissional.identificador
    const dataInicio = data.format('YYYY-MM-DD')
    const dataFim = data.endOf('month').format('YYYY-MM-DD')
    const identificadoresDasUnidades = unidadeDaEmpresaSelecionada ? [unidadeDaEmpresaSelecionada] : []

    await listarHorariosLivres(identificadorDoProfissional, dataInicio, dataFim, tipoDeAgendamento, identificadoresDasUnidades)
  }

  const agendamento = {
    ...valoresIniciais,
    convenio: convenios && !valoresIniciais.identificadorDoAgendamento ? convenios[0].identificador : valoresIniciais.convenio,
  }

  return (
    <Formulario
      adicionarBloqueioNaAgendaDoProfissionalDeSaude={adicionarBloqueioNaAgendaDoProfissionalDeSaude}
      adicionarAgendamento={adicionarAgendamentoDoProfissional}
      adicionarPreCadastroDoPaciente={adicionarPreCadastroDoPacienteNoAgendamento}
      convenios={convenios}
      dataBaseHorariosLivres={dataBaseHorariosLivres}
      grausDeRelacoes={grausDeRelacoes}
      horariosLivres={horariosLivres}
      identificadorDoProfissional={identificadorDoProfissional}
      limparAlertasDoAgendamento={limparAlertasDoAgendamento}
      limparDadosDoPaciente={limparDadosDoPacienteDoAgendamento}
      limparHorariosLivres={limparHorariosLivres}
      listarGrausDeRelacoes={listarGrausDeRelacoes}
      listarHorariosLivres={listarHorariosLivresDoProfissional}
      listarSexos={listarSexos}
      listarProprietariosDeTelefones={listarProprietariosDeTelefones}
      listarTiposDeHorarios={listarTiposDeHorarios}
      match={match}
      motivosDeBloqueio={motivosDeBloqueio}
      fechar={fechar}
      pacientes={pacientes}
      pacienteEmEspera={pacienteEmEspera}
      pacienteSemPrograma={pacienteSemPrograma}
      pesquisarPacientes={pesquisarPacientes}
      proprietariosDeTelefones={proprietariosDeTelefones}
      proximosAgendamentos={proximosAgendamentos}
      recorrenciasDoAgendamento={recorrenciasDoAgendamento}
      selecionarPaciente={selecionarPaciente}
      sexos={sexos}
      tiposDeAgendamento={tiposDeAgendamento}
      tiposDeHorarios={tiposDeHorarios}
      unidades={unidadesFiltradas}
      valoresIniciais={agendamento}
    />
  )
}